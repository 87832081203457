import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Camo Clash Paintball Arena
			</title>
			<meta name={"description"} content={"Taktik Ruhunuzu Serbest Bırakın - Camo Clash Paintball Arena'ya Hoş Geldiniz"} />
			<meta property={"og:title"} content={"Home | Camo Clash Paintball Arena"} />
			<meta property={"og:description"} content={"Taktik Ruhunuzu Serbest Bırakın - Camo Clash Paintball Arena'ya Hoş Geldiniz"} />
			<meta property={"og:image"} content={"https://jake-rembis.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://jake-rembis.com/img/4905919.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://jake-rembis.com/img/4905919.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://jake-rembis.com/img/4905919.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://jake-rembis.com/img/4905919.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://jake-rembis.com/img/4905919.png"} />
			<meta name={"msapplication-TileImage"} content={"https://jake-rembis.com/img/4905919.png"} />
		</Helmet>
		<Components.Header>
			<Override slot="quarklycommunityKitMenu" />
		</Components.Header>
		<Section
			background="linear-gradient(0deg,rgba(255, 255, 255, 0.6) 0%,rgba(255, 255, 255, 0.6) 100%),url(https://jake-rembis.com/img/1.jpg) center center/cover no-repeat"
			min-height="100vh"
			lg-padding="140px 0 88px 0"
			quarkly-title="Hero-21"
			padding="88px 0 88px 0"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				flex-wrap="wrap"
				align-items="center"
				justify-content="center"
			/>
			<Text
				letter-spacing="2px"
				margin="0px 0px 16px 0px"
				color="--greyD2"
				font="--base"
				lg-width="100%"
				lg-text-align="center"
				text-align="center"
				text-transform="uppercase"
			>
				Paintball Arena
			</Text>
			<Text
				color="--primary"
				font="--headline1"
				sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				margin="0px 0px 42px 0px"
				lg-width="100%"
				md-width="100%"
				lg-text-align="center"
				lg-margin="0px 0px 20px 0px"
				lg-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				text-align="center"
				letter-spacing="30px"
			>
				Camo Clash{" "}
			</Text>
			<Text
				lg-text-align="center"
				text-align="center"
				width="50%"
				margin="0px 0px 36px 0px"
				color="--darkL2"
				font="--lead"
				lg-width="100%"
			>
				Camo Clash Paintball Arena, hem heyecan arayanlar hem de strateji meraklıları için adrenalin dolu bir deneyim sunuyor. Arenamız, heyecan ve rekabet isteyenler için bir savaş alanıdır ve dostça bir çatışma veya ciddi bir turnuva için mükemmel bir ortam sağlar.
			</Text>
			<Box display="flex" sm-flex-direction="column">
				<Link
					color="--light"
					font="--lead"
					sm-margin="0px 0px 20px 0px"
					sm-width="100%"
					hover-color="--dark"
					border-width="2px"
					border-style="solid"
					href="/about"
					text-decoration-line="initial"
					border-radius="75px"
					background="--color-dark"
					border-color="--color-dark"
					padding="10px 23px 10px 23px"
					margin="0px 24px 0px 0px"
					transition="background-color 0.3s linear 0s"
					hover-background="rgba(4, 8, 12, 0)"
					sm-text-align="center"
				>
					İletişim
				</Link>
				<Link
					text-decoration-line="initial"
					sm-text-align="center"
					border-width="2px"
					sm-margin="0px 0px 0px 0px"
					background="rgba(4, 8, 12, 0)"
					border-color="--color-dark"
					color="--dark"
					border-radius="75px"
					transition="background-color 0.3s linear 0s"
					hover-color="--light"
					href="/faq"
					padding="10px 23px 10px 23px"
					hover-background="--color-dark"
					border-style="solid"
					font="--lead"
					margin="0px 0px 0px 0px"
					sm-width="100%"
				>
					Sıkça Sorulan Sorular
				</Link>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" background="#EDF2F6" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Neden Camo Clash Paintball Arenasını Seçmelisiniz?
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(4, 1fr)" grid-gap="20px" md-grid-template-columns="1fr">
				<Box
					padding="25px 25px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Dinamik Savaş Alanları
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Yoğun ormanlık alanlardan karmaşık kentsel yapılara kadar çeşitli arazi haritalarımız her oyunu yeni bir meydan okuma haline getirir.
					</Text>
				</Box>
				<Box
					padding="25px 25px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Yüksek Kaliteli Ekipman
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Optimize edilmiş bir oyun deneyimi için en üst düzey paintball işaretleyicileri ve güvenlik ekipmanları ile donatılmıştır.
					</Text>
				</Box>
				<Box
					padding="25px 25px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Takım Oluşturma Cenneti
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Kurumsal etkinlikler ve grup gezileri için ekip çalışmasını ve dostluğu teşvik eden mükemmel bir yer.
					</Text>
				</Box>
				<Box
					padding="25px 25px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Etkinlikler ve Ligler
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Tüm beceri seviyeleri için düzenli olarak planlanan etkinlikler, ligler ve turnuvalar.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-2">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				justify-content="center"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				width="75%"
			>
				<Box
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 65% 0px"
					width="100%"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						position="absolute"
						display="block"
						right={0}
						min-height="100%"
						bottom={0}
						src="https://jake-rembis.com/img/2.jpg"
						object-fit="cover"
						width="100%"
						top={0}
						left={0}
					/>
				</Box>
			</Box>
			<Box
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				justify-content="space-between"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="25%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
			>
				<Text
					margin="0px 0px 40px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Camo Clash Paintball Arena - Stratejilerin Ortaya Çıktığı ve Efsanelerin Yaratıldığı Yer
				</Text>
				<Box
					height="243px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="243px"
					overflow-y="hidden"
					border-radius="100%"
				>
					<Image
						right={0}
						bottom={0}
						min-height="100%"
						src="https://jake-rembis.com/img/3.jpg"
						object-fit="cover"
						width="100%"
						left={0}
						position="absolute"
						display="block"
						top={0}
						object-position="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Contacts />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});